import React, { ReactElement } from 'react'
import Layout from '../templates/layout'

import '../scss/takeda-oncology-here2assist.scss'

import SEO from '../components/SEO'
import { IStaticImageProps } from 'gatsby-plugin-image/dist/src/components/static-image.server'
import { StaticImage } from 'gatsby-plugin-image'
import ExternalLink from '../components/ExternalLink'

type ListItemProps = {
	title: string
	text: string
	Img: ReactElement<IStaticImageProps>
}

type Props = {
	location: Location
}

const TakedaOncologyHere2Assist = ({ location }: Props) => {
	return (
		<Layout>
			<SEO
				SEOData={{
					title: 'Takeda Oncology Here2Assist™ | EXKIVITY® (mobocertinib)',
					description:
						'Explore a patient support program to help with coverage, financial, and educational resources for people with EGFR Exon20 insertion-positive NSCLC taking EXKIVITY. Please see the Patient Information, Safety, and Boxed Warning.',
					path: location.pathname,
				}}
			/>
			<div className='grid grid-cols-12 pt-16 lg:pb-16 pb-10 md:shadow-inner-xl shadow-none'>
				<div className='col-span-12 w-10/12 mx-auto h-auto -z-10'>
					<StaticImage
						src='../images/takeda-oncology-here2assist/Takeda-oncology-here2assist.png'
						alt='Takeda Oncology Here2Assist logo'
						className='lg:w-1/3 w-2/3'
					/>
				</div>
			</div>
			<div
				className='grid grid-cols-12 bg-gradient-to-r from-lightGrey py-10'
				id='takeda-oncology-here2assist-container'
			>
				<div className='col-start-2 col-span-10 mb-2'>
					<div className='text-red md:text-2xl text-xl font-bold'>
						From finding financial assistance that may be right for you to
						understanding your disease, Takeda Oncology Here2Assist™ can provide the
						information you need throughout your treatment.
					</div>
					<div className='font-bold my-5'>Takeda Oncology Here2Assist:</div>
					<div className='list'>
						<ul className='list-outside'>
							<li>
								Works with your insurance company to help you get started on your
								medication
							</li>
							<li>
								Identifies available financial assistance that may be right for you
							</li>
							<li>
								May help you get started on treatment if there is a delay in insurance
								coverage determination
							</li>
							<li>Connects you to additional support services and resources</li>
							<li>
								Identifies specialty pharmacies to help fill and ship your prescriptions
								appropriately
							</li>
							<li>Conducts regular follow-up calls with you</li>
							<li>Sends you status updates and reminders via text messages*</li>
							<li>
								Connects you with nurse navigators to support your product education
								journey
							</li>
						</ul>
					</div>
					<div className='grid grid-cols-10 mt-10 flex lg:items-center justify-center'>
						<div className='md:col-span-1 col-span-2 md:items-center items-start '>
							<StaticImage
								src='../images/takeda-oncology-here2assist/patient-assistance.png'
								alt=''
								className='w-4/6'
							/>
						</div>
						<div className='md:col-span-9 col-span-8'>
							<div>
								<span className='font-bold'>ACCESS SUPPORT:</span> Once enrolled, Takeda
								Oncology Here2Assist case managers can work with you and your healthcare
								provider to determine your coverage options, and provide additional
								support throughout your treatment.
							</div>
						</div>
					</div>
					<div className='grid grid-cols-10 mt-10 flex lg:items-center justify-center'>
						<div className='md:col-span-1 col-span-2 md:items-center items-start '>
							<StaticImage
								src='../images/takeda-oncology-here2assist/financial-assistance.png'
								alt=''
								className='w-2/3'
							/>
						</div>
						<div className='md:col-span-9 col-span-8'>
							<div>
								<span className='font-bold'>FINANCIAL ASSISTANCE:</span> If you need
								assistance affording your medication, Takeda Oncology Here2Assist can
								help identify financial assistance programs that may be able to help you
								with the cost of your treatment.
							</div>
						</div>
					</div>
					<div className='grid grid-cols-10 mt-10 flex lg:items-center justify-center'>
						<div className='md:col-span-1 col-span-2 md:items-center items-start '>
							<StaticImage
								src='../images/takeda-oncology-here2assist/hub-case-manager.png'
								alt=''
								className='w-2/3'
								layout='fullWidth'
							/>
						</div>
						<div className='md:col-span-9 col-span-8'>
							<div>
								<span className='font-bold'>HELPFUL RESOURCES:</span> Takeda Oncology
								Here2Assist case managers can provide information about additional
								resources that may help address day-to-day concerns associated with your
								treatment.
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='grid grid-cols-12'>
				<div className='col-start-2 col-span-10 py-8'>
					<div className='text-red md:text-2xl text-xl font-bold'>
						Takeda Oncology Co-Pay Assistance Program
					</div>
					<div className='font-bold my-8'>
						If you have commercial insurance and are concerned about your
						out-of-pocket costs, the Takeda Oncology Co-Pay Assistance Program
						<sup>&dagger;</sup> may be able to help reduce the out-of-pocket costs
						associated with your medication. You could pay as little as $0 per
						prescription. Terms and Conditions apply.<sup>&dagger;</sup>
					</div>
				</div>
				<div className='md:col-start-3 col-start-2 md:col-span-8 col-span-10 bg-lightGrey rounded-tl-3xl rounded-br-3xl md:p-12 p-10'>
					To learn more about Takeda Oncology Here2Assist, call to speak with a case
					manager at{' '}
					<span className='whitespace-nowrap font-normal'>1-844-817-6468</span>,
					Option 2, or visit{' '}
					<a href='https://www.here2assist.com' target='_blank' rel='noreferrer'>
						www.Here2Assist.com
					</a>
					. <span className='font-bold text-red'>Let’s Talk.</span> We’re available
					Monday-Friday, 8AM-8PM ET.
				</div>
				<div className='col-start-2 col-span-10 mt-20'>
					<div>
						*Patients will need to enroll in the texting program to receive text
						messages.
					</div>
					<div className='mb-12'>
						<sup>&dagger;</sup>By enrolling in the Takeda Oncology Co-Pay Assistance
						Program (the “Program”), you acknowledge that you currently meet the
						eligibility criteria and will comply with the following terms and
						conditions: You must be at least 18 years old, a resident of the United
						States or a US Territory, and have commercial (private) prescription
						insurance that does not cover the entire cost of the medication. The
						Program is not valid for patients whose prescription claims are eligible
						to be reimbursed, in whole or in part, by any state or federal government
						program, including, but not limited to, Medicare, Medicare Advantage,
						Medigap, Medicaid, Department of Defense (DoD), Veterans Affairs (VA),
						TRICARE, Puerto Rico Government Insurance, or any state patient or
						pharmaceutical assistance program. Patients who become eligible for or
						start using government insurance will no longer be eligible for the
						Program. The Program is not valid if the entire cost of your prescription
						is reimbursable by a private insurance plan or other private health or
						pharmacy benefit programs. You are responsible for reporting receipt of
						Program assistance to any insurer, health plan, or other third party who
						pays for or reimburses any part of the medication cost, as may be
						required. You agree that you will not submit the cost of any portion of
						the product dispensed pursuant to this Program to a federal or state
						healthcare program (including, but not limited to, Medicare, Medicare
						Advantage, Medicaid, TRICARE, VA, DOD, etc.) for purposes of counting it
						toward your out-of-pocket expenses, and to notify Takeda Oncology
						Here2Assist™ if you become eligible for a federal or state healthcare
						program. This Program is not conditioned on any past, present, or future
						purchase of any Takeda product, including refills. This Program is valid
						for 12 months, and your co-pay card may be renewed every 12 months,
						subject to continued eligibility. This offer is not valid with any other
						program, discount, or offer involving your prescribed Takeda Oncology
						medication. This offer may be rescinded, revoked, or amended without
						notice. No reproductions. This offer is void where prohibited by law,
						taxed, or restricted. Limit one offer per purchase. No income requirements
						or membership fees. This Program is not health insurance. Cash value of
						1/100 of 1¢. For questions about this offer, please contact the Takeda
						Oncology Co-Pay Assistance Program, a patient support service of Takeda
						Oncology Here2Assist, at{' '}
						<strong className='whitespace-nowrap'>1-844-817-6468</strong>, Option 2,
						Monday-Friday, 8AM-8PM ET.
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default TakedaOncologyHere2Assist
